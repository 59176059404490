function notification(message, type) {
    type = typeof type !== 'undefined' ? type : 'success';
    noty({
        theme: "relax",
        layout: "top",
        animation: {
            open: 'animated flipInX',
            close: 'animated flipOutX',
            easing: 'swing',
            speed: 500
        },
        type: type,
        text: message,
        timeout: 5000
    });
}

function openPanelForm() {
    var height = $(".panel-inline .panel-body").innerHeight();
    if (height == 0) {
        height = $(".panel-inline .panel-body")[0].scrollHeight + 30;
    }

    $(".panel-inline .panel-body").animate({
        height: height,
        paddingTop: 15,
        paddingBottom: 15
    }, 500, function () {
        $(this).css({
            overflow: 'inherit',
            height: 'auto'
        });
    });

    $(".panel-inline .panel-heading").css('overflow', 'hidden').animate({
        height: 0,
        paddingTop: 0,
        paddingBottom: 0
    }, 500);

    $(".panel-inline .form input:not([type='hidden']), .panel-inline .form select").val("");
    $(".panel-inline .form").find("input[type=text], textarea, select").filter(":visible:first").focus();
}

function closePanelForm() {
    var height = $(".panel-inline .panel-heading").innerHeight();
    if (height == 0) {
        height = $(".panel-inline .panel-heading")[0].scrollHeight + 30;
    }
    $(".panel-inline .panel-heading").animate({
        height: height,
        paddingTop: 15,
        paddingBottom: 15
    }, 500, function () {
        $(this).css('overflow', 'inherit');
    });
    $(".panel-inline .panel-body").css('overflow', 'hidden').animate({
        height: 0,
        paddingTop: 0,
        paddingBottom: 0
    }, 500);
}

function scrollTo(target) {
    $("html, body").delay(500).animate({scrollTop: $(target).offset().top + 60}, 1500);
}

$(document).ready(function () {
    $(".panel-inline .btn-add").click(function () {
        openPanelForm();
    });

    $(".panel-inline .btn-cancel").click(function () {
        closePanelForm();
    });

});


function videoEmbed() {

    $('#trailer').html(function (i, html) {
        return convertMedia(html);
    });

}

function convertMedia(html) {
    var pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
    var pattern2 = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;

    if (pattern1.test(html)) {
        var replacement = '<iframe class="embed-responsive-item" src="//player.vimeo.com/video/$1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
        var html = html.replace(pattern1, replacement);
    }

    if (pattern2.test(html)) {
        var replacement = '<iframe class="embed-responsive-item" src="//www.youtube.com/embed/$1" frameborder="0" allowfullscreen></iframe>';
        var html = html.replace(pattern2, replacement);
    }

    return html;
}

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf_token"]').attr('content')
    }
});
